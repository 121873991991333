// src/service/risk.service.js
import client from '../lib/api/client'; // axios 클라이언트 임포트
import { formatDate, formatDateTime } from '../utils/formatDate'; // 날짜 포맷팅 함수 임포트


// 회장 이름 회사이름 가져옴
export const getCompany = async (com_name) => {
  try {
    const response = await client.get(`/company/${com_name}`);
    return response.data;
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error; // 에러를 호출자에게 전달
  }
};
// CEO risk  Score 가져옴 엑사원버전 !
export const nowRiskScore = async (com_name, date) => {
  try {
    const formattedDate = formatDate(date); // 날짜를 원하는 형식으로 포맷
    const response = await client.get('/ceo_risk_exaone', {
      params: { // params로 수정
        com_name: com_name,
        date: formattedDate, // 포맷된 날짜를 사용
      },
    });
    console.log('받은 데이터:', response.data); // 받은 데이터 콘솔에 출력

  return response.data;
   
  } catch (error) {
    console.log('API 요청 오류:', error);
    throw error;
  }
};


//기사 가져오기
export const getArticleList = async (com_name, date) => {
  try {
    const formattedDate = formatDate(date); 
    const response = await client.get('/articles', {
      params: {
        com_name: com_name,
        date: formattedDate,
      },
    });
    return response.data; // 응답 데이터 반환
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error;
  }
};

//랭크 가져오기
export const getRank = async (date, dateRange,cancelToken) => {
  try {
    const formattedDate = formatDate(date); 
    const response = await client.get('/total_ceo_risk', {
      params: {
        selected_date  : formattedDate,
        grouping_type : dateRange,
      },
      elToken: cancelToken 
    });
    return response.data; // 응답 데이터 반환
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error;
  }
};

//차트 가져오기 이안에 요약기사 분리됨 코드 다다음주 고칠예정
export const getChartData = async (com_name, date, dateRange,cancelToken) => {
  try {
    const formattedDate = formatDate(date); 
    const response = await client.get('/graph_info_exaone', {
      params: {
        com_name: com_name,
        selected_date_str : formattedDate,
        grouping_type : dateRange,
      },
      cancelToken: cancelToken 
    });
    console.log('차트데이터:', response.data); // 응답 데이터 출력
    return response.data; // 응답 데이터 반환
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error;
  }
};

// 키워드 클라우드 가져오기
export const getKeywordCloudData = async (com_name, date) => {
  try {
    const formattedDate = formatDate(date); 
    const response = await client.get('/word_cloud', {
      params: {
        query: com_name,
        target_date : formattedDate,
      },
    });
    return response.data; // 응답 데이터 반환
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error;
  }
};

// export const sendFeedback = async (feedbackData) => {
//   try {
//     const response = await client.post('/send-email', feedbackData, {
//       headers: {
//         'Content-Type': 'application/json', // Content-Type 헤더 설정
//       },
//     });
//     console.log('피드백 전송 성공:', response.data); // 응답 데이터 출력
//     return response.data; // 성공 시 응답 데이터 반환
//   } catch (error) {
//     console.log('피드백 전송 오류:', error); // 오류 시 콘솔에 출력
//     throw error; // 오류 던지기
//   }
// };

export const sendFeedback = async (feedbackData) => {
  try {
    console.log('보내는 피드백 데이터:', feedbackData); // 요청 전 데이터 로그
    const response = await client.post('/send-email', feedbackData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('피드백 전송 성공:', response.data); // 응답 데이터 출력
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log('피드백 전송 오류 응답:', error.response.data); // 서버 응답 오류 데이터
      console.log('피드백 전송 오류 상태:', error.response.status); // 오류 상태 코드
    } else if (error.request) {
      console.log('피드백 전송 오류 요청:', error.request); // 요청 데이터
    } else {
      console.log('피드백 전송 오류 메시지:', error.message); // 오류 메시지
    }
    throw error;
  }
};


// 클라이언트 IP 가져오기 함수
export const getClientIP = async () => {
  try {
    const response = await client.get('https://ipinfo.io/json');
    console.log('클라이언트 IP:', response.data); // 응답 데이터 출력
    return response.data; // 성공 시 응답 데이터 반환
  } catch (error) {
    console.error('IP 가져오기 오류:', error); // 오류 시 콘솔에 출력
    throw error; // 오류 던지기
  }
};

// 방문자 정보 전달
export const visitorCount = async (ip, uuid, date) => {
  try {
    const DateTime = formatDateTime(date);
    const response = await client.post('visitor-count', { 
      ip: ip,
      uuid: uuid,
      target_date: DateTime,
    });
    return response.data; // 서버로부터 받은 데이터를 반환
  } catch (error) {
    console.error('클라이언트 정보 전송 오류:', error); // 오류 시 콘솔에 출력
    throw error; // 오류를 던져 호출자에게 알림
  }
};


// 방문자 카운팅
export const visitor_Counting = async () => {
  try {
    const response = await client.get('visitor-count');
    
    return response.data; //
  } catch (error) {
    console.error('클라이언트 정보 전송 오류:', error); // 오류 시 콘솔에 출력
    throw error; // 오류 던지기
  }
};


// 인접기사 리스트
export const getCmpArticleList = async (com_name, date) => {
  try {
    const formattedDate = formatDate(date); 
    const response = await client.get('/compared_articles', {
      params: {
        com_name: com_name,
        date: formattedDate,
      },
    });
    return response.data; // 응답 데이터 반환
  } catch (error) {
    //console.error('API 요청 오류:', error);
    console.log('API 요청 오류:', error);
    throw error;
  }
};

// // 요약기사  gpt
// export const getSummaryInfo = async (com_name, date) => {
//   try {
//     const formattedDate = formatDate(date);
//     const response = await client.get('/summary_info', {
//       params: {
//         com_name: com_name,
//         date: formattedDate,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log('API 요청 오류:', error);
//     throw error;
//   }
// };

// 요약기사 
export const getSummaryInfo = async (com_name, date, dateRange,cancelToken) => {
  try {
    const formattedDate = formatDate(date);
    const response = await client.get('/summary_exaone', {
      params: {
        com_name: com_name,
        selected_date_str : formattedDate,
        grouping_type: dateRange,
      },
      cancelToken: cancelToken 
    });
    return response.data;
  } catch (error) {
    console.log('API 요청 오류:', error);
    throw error;
  }
};

// // 대책방안 파트 gpt
// export const getWipInfo = async (com_name, date, dateRange) => {
//   try {
//     const formattedDate = formatDate(date);
//     const response = await client.get('/wip_info_grouping', {
//       params: {
//         target_company : com_name,
//         target_date_str: formattedDate,
//         grouping_type  : dateRange
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log('API 요청 오류:', error);
//     throw error;
//   }
// };
// 대책방안 파트 엑사원
export const getWipInfo = async (com_name, date, dateRange,cancelToken) => {
  try {
    const formattedDate = formatDate(date);
    const response = await client.get('/wip_exaone', {
      params: {
        target_company : com_name,
        target_date_str: formattedDate,
        grouping_type  : dateRange
      },
      cancelToken: cancelToken 
    });
    return response.data;
  } catch (error) {
    console.log('API 요청 오류:', error);
    throw error;
  }
};

// 엑사원 버전
export const getAvg = async (com_name, date) => {
  try {
    const formattedDate = formatDate(date);
    const response = await client.get('/ceo_risk_avg_exaone', {
      params: {
        com_name: com_name,
        date: formattedDate,
      },
    });
    return response.data;
  } catch (error) {
    console.log('API 요청 오류:', error);
    throw error;
  }
};