import React, { useState, useEffect } from 'react';
import { getWipInfo } from '../service/risk.service';
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트
import axios from 'axios'; // axios 임포트
import { ClipLoader } from 'react-spinners'; // react-spinners의 ClipLoader 임포트

function CeoLlmBox() {
    const [wipInfo, setWipInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // 초기 로딩 상태를 false로 설정
    const { com_name, selectDate, dateRange } = useStore(state => ({
        com_name: state.com_name,
        selectDate: state.selectDate,
        dateRange: state.dateRange,
    }));

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트된 상태를 추적하기 위한 플래그
        const source = axios.CancelToken.source(); // cancelToken 생성

        const fetchWipInfo = async () => {
            setIsLoading(true); // API 호출 시작 시 로딩 상태로 변경
            try {
                const data = await getWipInfo(com_name, selectDate, dateRange, source.token); // CancelToken 전달
                if (isMounted) {
                    // wipInfo가 배열인지 확인
                    if (Array.isArray(data)) {
                        setWipInfo(data);
                    } else {
                        setWipInfo([]); // 배열이 아닌 경우 빈 배열로 설정
                    }
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching WIP info:', error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false); // 로딩 상태를 false로 설정
                }
            }
        };

        if (com_name && selectDate && dateRange) {
            fetchWipInfo(); // 의존성이 존재할 때만 데이터 로드
        }

        // 컴포넌트가 언마운트될 때 요청 취소 및 상태 업데이트 방지
        return () => {
            isMounted = false; // 컴포넌트가 언마운트되면 상태 업데이트 방지
            source.cancel('컴포넌트가 언마운트되어 요청이 취소되었습니다.');
        };
    }, [com_name, selectDate, dateRange]);

    // 텍스트가 너무 길면 잘라서 '...' 추가하는 함수
    const truncateText = (text, maxLength) => {
        // if (text.length > maxLength) {
        //     return text.slice(0, maxLength) + '...';
        // }
        return text;
    };

    return (
        <div>
            <p className='mx-auto text-base font-medium pt-2 p-3'>LLM Box</p>
            <div className="wip-info-container p-4 h-96 text-xs custom:text-sm font-medium">
                {isLoading ? (
                    // 로딩 중일 때 스피너 표시
                    <div className="flex justify-center items-center">
                        <ClipLoader color="#36D7B7" size={50} />
                    </div>
                ) : wipInfo.length > 0 ? ( // 데이터가 있을 때
                    wipInfo.map((info, index) => (
                        <div key={index} className="info-item mb-2">
                            <p>{truncateText(info, 70)}</p>
                        </div>
                    ))
                ) : ( // 데이터가 없을 때
                    <p>토큰이 없습니다. 추가결제를 해주세요.</p>
                )}
            </div>
        </div>
    );
}

export default CeoLlmBox;
